
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';

import PrimeVueToast from 'primevue/toast';

import { useToast } from 'primevue/usetoast';

import PrimeVueConfirmPopup from 'primevue/confirmpopup';

import { useConfirm } from 'primevue/useconfirm';

import type {
  EntriesPaginator,
  Projects,
  Users,
  Creatives,
  Creative,
} from '@/types';

import {
  ENTRIES,
  USER_TYPE_AFFILIATE,
  CREATIVE_TYPES,
  CREATIVE_TYPES_MAP,
  CREATIVE_APPROVAL_STATUSES,
  CREATIVE_APPROVAL_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    PrimeVueToast,
    PrimeVueConfirmPopup,
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
    CreativeApprovalStatus: defineAsyncComponent(() => import('@/components/CreativeApprovalStatus.vue')),
  },
  setup() {
    const router = useRouter();

    const i18n = useI18n();

    const toast = useToast();

    const confirm = useConfirm();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const creatives = reactive<Creatives>({ ...ENTRIES });

    const creativesFilters = reactive({
      project_id: null,
      user_id: null,
      type: null,
      name: null,
      approval_status: null,
    });

    const fetchCreatives = async (creativesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/creative/getCreativesPaginator',
        {
          ...creativesPaginator,
          ...creativesFilters,
        },
      );

      Object.assign(creatives, response.data);
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? creativesFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const onProjectChange = (): void => {
      creativesFilters.user_id = null;

      fetchUsersWrapper();
    };

    const cloneCreative = async (creativeId: number): Promise<void> => {
      try {
        const response = await axios.post('/api/creative/cloneCreative', {
          id: creativeId,
        });

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_clone_creative'),
          life: 5000,
        });

        await router.push(`/creatives/${response.data.id}`);
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }
    };

    const confirmCloneCreative = (event: PointerEvent, creativeId: number): void => {
      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: 'Are you sure you want to clone this creative?',
        icon: 'pi pi-question-circle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        acceptLabel: 'Clone',
        rejectLabel: 'Cancel',
        rejectClass: 'p-button-outlined p-button-sm',
        acceptClass: 'p-button-sm',
        accept: () => {
          cloneCreative(creativeId);
        },
      });
    };

    const previewCreative = (creative: Creative): void => {
      toast.removeAllGroups();

      toast.add({
        severity: 'info',
        styleClass: 'p-toast-push',
        detail: creative,
        group: 'bc',
        life: 5000,
      });
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    return {
      CREATIVE_TYPES,
      CREATIVE_TYPES_MAP,
      CREATIVE_APPROVAL_STATUSES,
      CREATIVE_APPROVAL_STATUSES_MAP,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      creatives,
      creativesFilters,
      fetchCreatives,
      onProjectChange,
      confirmCloneCreative,
      previewCreative,
    };
  },
});
