import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Suspense as _Suspense, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "w-3 mr-2"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mb-2 font-bold" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_9 = {
  key: 1,
  class: "p-fluid"
}
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueToast = _resolveComponent("PrimeVueToast")!
  const _component_PrimeVueConfirmPopup = _resolveComponent("PrimeVueConfirmPopup")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_CreativeApprovalStatus = _resolveComponent("CreativeApprovalStatus")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_UserColumn = _resolveComponent("UserColumn")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Entries = _resolveComponent("Entries")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PrimeVueToast, {
      position: "bottom-right",
      group: "bc"
    }, {
      message: _withCtx(({ message }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(message.detail.compiled_name), 1),
          _createElementVNode("div", _hoisted_3, [
            (message.detail.icon_url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("img", {
                    class: "w-full border-round-sm",
                    src: message.detail.icon_url
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(message.detail.title), 1),
              _createElementVNode("div", null, _toDisplayString(message.detail.description), 1)
            ])
          ]),
          (message.detail.image_url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "block mt-2 w-full border-round-sm",
                src: message.detail.image_url
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_PrimeVueConfirmPopup),
    _createVNode(_component_Entries, {
      title: _ctx.$t('creatives'),
      "href-prefix": "creatives"
    }, {
      tables: _withCtx(() => [
        _createVNode(_component_EntriesDataTable, {
          entries: _ctx.creatives,
          "entries-handler": _ctx.fetchCreatives,
          "href-prefix": "creatives"
        }, {
          actions: _withCtx(({ data, index }) => [
            _withDirectives(_createVNode(_component_PrimeVueButton, {
              class: "p-button-rounded p-button-text p-button-secondary",
              icon: "pi pi-eye",
              onClick: ($event: any) => (_ctx.previewCreative(data))
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('preview'),
                void 0,
                { left: true }
              ]
            ]),
            (_ctx.user.isStaff())
              ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CreativeApprovalStatus, {
                      "creative-id": data.id,
                      "creative-approval-status": _ctx.creatives.data[index].approval_status,
                      "onUpdate:creative-approval-status": ($event: any) => ((_ctx.creatives.data[index].approval_status) = $event)
                    }, null, 8, ["creative-id", "creative-approval-status", "onUpdate:creative-approval-status"])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      width: "14px",
                      height: "14px"
                    })
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_PrimeVueButton, {
              class: "p-button-rounded p-button-text p-button-secondary",
              icon: "pi pi-clone",
              onClick: ($event: any) => (_ctx.confirmCloneCreative($event, data.id))
            }, null, 8, ["onClick"]), [
              [
                _directive_tooltip,
                _ctx.$t('clone'),
                void 0,
                { left: true }
              ]
            ])
          ]),
          columns: _withCtx(() => [
            _createVNode(_component_PrimeVueColumn, {
              field: "id",
              header: "ID",
              sortable: ""
            }),
            (_ctx.user.isSuperAdmin())
              ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                  key: 0,
                  field: "project_id",
                  header: _ctx.$t('project'),
                  sortable: ""
                }, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(data.project.name), 1)
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            (_ctx.user.isStaff())
              ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                  key: 1,
                  field: "user_id",
                  header: _ctx.$t('user'),
                  sortable: ""
                }, {
                  body: _withCtx(({ data }) => [
                    (_openBlock(), _createBlock(_Suspense, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_UserColumn, {
                          user: data.user,
                          "show-email-as-link": ""
                        }, null, 8, ["user"])
                      ]),
                      fallback: _withCtx(() => [
                        _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                      ]),
                      _: 2
                    }, 1024))
                  ]),
                  _: 1
                }, 8, ["header"]))
              : _createCommentVNode("", true),
            _createVNode(_component_PrimeVueColumn, {
              field: "type",
              header: _ctx.$t('type'),
              sortable: ""
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.CREATIVE_TYPES_MAP[data.type].label), 1)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_PrimeVueColumn, {
              field: "name",
              header: _ctx.$t('name'),
              sortable: ""
            }, null, 8, ["header"]),
            _createVNode(_component_PrimeVueColumn, {
              field: "approval_status",
              header: _ctx.$t('approval_status'),
              sortable: ""
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_PrimeVueTag, {
                  value: _ctx.$t(_ctx.CREATIVE_APPROVAL_STATUSES_MAP[data.approval_status].translate_key),
                  severity: _ctx.CREATIVE_APPROVAL_STATUSES_MAP[data.approval_status].severity
                }, null, 8, ["value", "severity"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_PrimeVueColumn, {
              field: "updated_at",
              header: _ctx.$t('updated_at'),
              sortable: ""
            }, null, 8, ["header"])
          ]),
          _: 1
        }, 8, ["entries", "entries-handler"])
      ]),
      filters: _withCtx(() => [
        (_ctx.user.isSuperAdmin())
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.creativesFilters.project_id,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.creativesFilters.project_id) = $event)),
                  _ctx.onProjectChange
                ],
                modelModifiers: { number: true },
                label: _ctx.$t('project'),
                loading: _ctx.projectsLoading,
                "show-clear": true,
                options: _ctx.projects.data,
                "option-value": "id",
                "option-label": "name"
              }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.user.isStaff())
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.creativesFilters.user_id,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.creativesFilters.user_id) = $event)),
                modelModifiers: { number: true },
                label: _ctx.$t('user'),
                loading: _ctx.usersLoading,
                "show-clear": true,
                options: _ctx.users.data,
                "option-value": "id",
                "option-label": "compiled_name",
                filter: true
              }, null, 8, ["modelValue", "label", "loading", "options"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_FieldDropdownNext, {
            modelValue: _ctx.creativesFilters.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.creativesFilters.type) = $event)),
            modelModifiers: { number: true },
            label: _ctx.$t('type'),
            "show-clear": true,
            options: _ctx.CREATIVE_TYPES
          }, null, 8, ["modelValue", "label", "options"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_FieldText, {
            modelValue: _ctx.creativesFilters.name,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.creativesFilters.name) = $event)),
            modelModifiers: { trim: true },
            label: _ctx.$t('name')
          }, null, 8, ["modelValue", "label"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_FieldDropdownNext, {
            modelValue: _ctx.creativesFilters.approval_status,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.creativesFilters.approval_status) = $event)),
            modelModifiers: { number: true },
            label: _ctx.$t('approval_status'),
            "show-clear": true,
            options: _ctx.CREATIVE_APPROVAL_STATUSES,
            "option-label-translate": ""
          }, null, 8, ["modelValue", "label", "options"])
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ], 64))
}